<template>
    <div>
        <v-container>
            <v-card class="pa-6">
                <v-card-title class="text-h5">{{ $translate("routes.DeletingWinners") }}</v-card-title>
                <v-card-text>
                    <v-form ref="form" @submit.prevent="buscarGanadores" v-model="formValid">
                        <!-- Selector de programa -->
                        <v-select v-model="selectedProgram" :items="programOptions" label="Selecciona el programa"
                            item-text="nombre" item-value="id" outlined required
                            :rules="[v => !!v || 'Por favor selecciona un programa']" @input="handleProgramChange" />

                        <!-- Selector de Recurrencia -->
                        <v-select v-model="selectedRecurrence" :items="recurrenceOptions"
                            label="Selecciona la recurrencia" item-text="nombre" item-value="id" outlined required
                            :rules="[v => !!v || 'Por favor selecciona una recurrencia']" />

                        <!-- Selector de Año -->
                        <v-select v-model="selectedYear" :items="yearOptions" label="Selecciona el año" outlined
                            required :rules="[v => !!v || 'Por favor selecciona un año']" />

                        <v-btn :disabled="loadingAll || !formValid" color="primary" block type="submit" class="mt-4"
                            background-color="#466be3">
                            Buscar ganadores
                        </v-btn>
                    </v-form>

                    <!-- Tabla de ganadores -->
                    <v-data-table v-if="ganadores.length > 0" v-model="selectedGanadores" :headers="headers"
                        :items="ganadores" :loading="loadingTable" show-select item-key="id" class="mt-4"
                        :items-per-page="50" :footer-props="{
                            'items-per-page-options': [50],
                            'items-per-page-text': 'Filas por página:',
                            'page-text': '{0}-{1} de {2}'
                        }">
                        <!-- Template para la columna de nombre -->
                        <template v-slot:item.nombre="{ item }">
                            <div class="d-flex align-center">
                                <v-avatar size="32" class="mr-2">
                                    <v-img :src="item.user.img" />
                                </v-avatar>
                                <div>
                                    <div>{{ item.user.full_name }}</div>
                                    <div class="caption grey--text">{{ item.cargo }}</div>
                                </div>
                            </div>
                        </template>

                        <!-- Template para acciones adicionales -->
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title>Ganadores del programa</v-toolbar-title>
                                <v-spacer></v-spacer>

                                <!-- Botón para eliminar seleccionados -->
                                <v-btn v-if="selectedGanadores.length > 0" color="error" @click="eliminarSeleccionados">
                                    Eliminar {{ selectedGanadores.length }} seleccionado{{
                                        selectedGanadores.length !== 1 ? 's' : '' }}
                                </v-btn>
                            </v-toolbar>
                        </template>
                    </v-data-table>

                    <!-- Mensaje cuando no hay ganadores -->
                    <v-alert v-else-if="busquedaRealizada" type="info" class="mt-4">
                        No se encontraron ganadores para los criterios seleccionados
                    </v-alert>

                </v-card-text>
            </v-card>
        </v-container>

        <Alert :open="message.dialog" :text="message.sms" :title="message.title" :type="message.type"
            :redirect="message.redirect" @close="message.dialog = false" />

        <v-overlay :value="loadingAll">
            <v-progress-circular :size="120" color="#466BE3" indeterminate>
                Cargando...
            </v-progress-circular>
        </v-overlay>

        <!-- Diálogo de confirmación para eliminación -->
        <v-dialog v-model="dialogConfirmDelete.show" max-width="400">
            <v-card>
                <v-card-title class="text-h5">Confirmar eliminación</v-card-title>
                <v-card-text>
                    <p class="text-subtitle-1 mb-3">¿Estás seguro de que deseas eliminar {{ selectedGanadores.length }}
                        ganador(es)?</p>
                    <v-list dense>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="subtitle-2 font-weight-bold">Programa:</v-list-item-title>
                                <v-list-item-subtitle class="subtitle-1">
                                    {{ getProgramaNombre(selectedProgram) }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="subtitle-2 font-weight-bold">Periodo:</v-list-item-title>
                                <v-list-item-subtitle class="subtitle-1">
                                    {{ getPeriodoNombre(selectedRecurrence) }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="subtitle-2 font-weight-bold">Año:</v-list-item-title>
                                <v-list-item-subtitle class="subtitle-1">
                                    {{ selectedYear }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text @click="dialogConfirmDelete.show = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" @click="confirmarEliminacion">
                        Eliminar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Api from "@/utils/api";
import Alert from "@/components/Alert.vue";

export default {
    name: 'DeletingWinners',
    components: {
        Alert,
    },
    data() {
        const currentYear = new Date().getFullYear();
        const previousYear = currentYear - 1;
        return {
            loadingAll: false,
            loadingTable: false,
            formValid: false,
            busquedaRealizada: false,
            programOptions: [],
            recurrenceOptions: [],
            selectedProgram: null,
            selectedRecurrence: null,
            selectedYear: null,
            yearOptions: [previousYear, currentYear],
            ganadores: [],
            headers: [
                { text: 'Seleccionar', value: 'data-table-select' },
                { text: 'Nombre', value: 'nombre', sortable: false },
                { text: 'País', value: 'user.country' },
                { text: 'Programa', value: 'programa.nombre' },
                { text: 'Periodo', value: 'periodo.nombre' }
            ],
            message: {
                dialog: false,
                title: "",
                sms: "",
                type: "",
                redirect: "",
            },
            token: null,
            selectedGanadores: [],
            dialogConfirmDelete: {
                show: false
            }
        };
    },
    created() {
        this.token = this.$route.params.token;
        if (!this.token) {
            this.token = localStorage.getItem("tokenB64");
        }
        this.getProgramas();
    },
    methods: {
        getProgramas() {
            Api.Reconocimientos()
                .getProgramas(this.token)
                .then((res) => res.json())
                .then((response) => {
                    this.programOptions = response.data.reverse();
                    if (this.programOptions.length > 0) {
                        this.updateRecurrenceOptions();
                    }
                })
                .catch((error) => {
                    console.error('Error al obtener programas:', error);
                    this.showAlert("Error", "No se pudieron cargar los programas", "error");
                });
        },

        handleProgramChange() {
            this.updateRecurrenceOptions();
        },

        updateRecurrenceOptions() {
            if (this.selectedProgram && this.programOptions) {
                const selectedProgramData = this.programOptions.find(program => program.id === this.selectedProgram);
                if (selectedProgramData && selectedProgramData.recurrencia) {
                    this.recurrenceOptions = selectedProgramData.recurrencia.periodos;
                    this.selectedRecurrence = null;
                }
            }
        },

        buscarGanadores() {
            this.loadingTable = true;
            this.busquedaRealizada = true;

            const data = {
                programa_id: this.selectedProgram,
                periodo_id: this.selectedRecurrence,
                vigencia: this.selectedYear
            };

            Api.Reconocimientos()
                .getGanadoresPrograma(this.token, JSON.stringify(data))
                .then((res) => res.json())
                .then((response) => {
                    if (response.data && Array.isArray(response.data)) {
                        this.ganadores = response.data;
                    } else {
                        this.ganadores = [];
                    }
                })
                .catch((error) => {
                    console.error('Error al obtener ganadores:', error);
                    this.showAlert("Error", "No se pudieron cargar los ganadores", "error");
                })
                .finally(() => {
                    this.loadingTable = false;
                });
        },

        getProgramaNombre(id) {
            const programa = this.programOptions.find(p => p.id === id);
            return programa ? programa.nombre : '';
        },

        getPeriodoNombre(id) {
            const periodo = this.recurrenceOptions.find(p => p.id === id);
            return periodo ? periodo.nombre : '';
        },

        eliminarSeleccionados() {
            if (!this.selectedGanadores.length) return;
            this.dialogConfirmDelete.show = true;
        },

        confirmarEliminacion() {
            this.loadingAll = true;
            this.dialogConfirmDelete.show = false;

            // Asegurarnos de que los IDs sean números enteros positivos (uint64)
            const ganadoresIds = this.selectedGanadores.map(g => parseInt(g.id));

            const data = {
                ganadores: ganadoresIds
            };

            // Verificar la estructura de los datos antes de enviar
            console.log('Datos a enviar:', data);

            Api.Reconocimientos()
                .eliminarGanadores(this.token, JSON.stringify(data))
                .then(async (res) => {
                    // Verificar el estado de la respuesta
                    if (!res.ok) {
                        const errorData = await res.json();
                        throw new Error(errorData.message || 'Error en la respuesta del servidor');
                    }
                    return res.json();
                })
                .then((response) => {
                    if (!response.error) {
                        this.showAlert(
                            "Eliminación completada",
                            `Se eliminaron ${this.selectedGanadores.length} ganadores correctamente`,
                            "success"
                        );
                        this.buscarGanadores(); // Refrescar la lista
                    } else {
                        throw new Error(response.error || "Error en la eliminación");
                    }
                })
                .catch((error) => {
                    console.error('Error en eliminación:', error);
                    this.showAlert(
                        "Error",
                        error.message || "Hubo un problema al eliminar los ganadores",
                        "error"
                    );
                })
                .finally(() => {
                    this.loadingAll = false;
                    this.selectedGanadores = [];
                });
        },

        showAlert(title, message, type) {
            this.message.dialog = true;
            this.message.title = title;
            this.message.sms = message;
            this.message.type = type;
        }
    }
};
</script>

<style lang="less" scoped>
.container-alert-form ::v-deep .v-alert__content {
    text-align: left;
}

.v-data-table {
    background-color: white;
}

.caption {
    font-size: 0.75rem;
}

.v-toolbar {
    border-bottom: 1px solid #e0e0e0;
}
</style>
